var swiperProduction = new Swiper('.production__swiper', {
    slidesPerView: 1,
    spaceBetween: 30,
    navigation: {
        nextEl: '.swiper-button-next-production',
        prevEl: '.swiper-button-prev-production',
    },
    breakpoints: {
        1201: {
            slidesPerView: 2,
        },
    },
});
var swiperInfo = new Swiper('.info__swiper', {
    slidesPerView: 1,
    navigation: {
        nextEl: '.swiper-button-next-info',
        prevEl: '.swiper-button-prev-info',
    },
    breakpoints: {
        1201: {
            slidesPerView: 'auto',
            spaceBetween: 30,
        },
        751: {
            slidesPerView: 2,
            spaceBetween: 30,
        },
    },
});

var swiperGrid = new Swiper('.orders__swiper-grid-desctop', {
    slidesPerView: 1,
    spaceBetween: 30,
    navigation: {
        nextEl: '.swiper-button-next-grid-desctop',
        prevEl: '.swiper-button-prev-grid-desctop',
    },
});
var swiperGrid = new Swiper('.orders__swiper-grid-mobile', {
    slidesPerView: 2,
    spaceBetween: 20,
    navigation: {
        nextEl: '.swiper-button-next-grid-mobile',
        prevEl: '.swiper-button-prev-grid-mobile',
    },
});

$('.btn-popup').magnificPopup();
$('.close-popup').click(function() {
    $.magnificPopup.close();
});

$('.btn-mob').click(function() {
    $('body').toggleClass('open-menu');
});

const forms = $('.form-idea form');
console.log(forms);
forms.map((i, el) => {
    console.log(el);
    $(el).validate({
        rules: {
            email: {
                required: true,
                email: true,
            },
            tel: {
                required: true,
            },
        },
        errorPlacement: function(error, element) {},

        submitHandler: function(form) {
            // sucess
            $.magnificPopup.open({
                items: {
                    src: '#popup-thank',
                },
            });
        },
    });
});

AOS.init({
    once: true,
});